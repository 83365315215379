<template>
  <div>
    <div class="menu">
      <div id="nav">
        <b-container class="menuContainer menuClass">
          <b-navbar type="light" toggleable="lg" id="topNav" align="center">
            <b-button class="burgerOuter" @click="toggle"
              ><b-img
                class="burger"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/06/burger_menu.png'"
              ></b-img
            ></b-button>
            <template v-if="isMobile(true)">
            <!-- <template v-if="days_away == '0' || days_away == '-4' || days_away == '-3'|| days_away == '-2' || days_away == '-1'"> -->
              <template v-if="live_scoring == 'Y'">
                <b-nav-item v-if="this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only') || this.CurrentTitle.includes('Scoreboard')" class="menuBar LeaderboardBtnMob pulse" :to="{
                    name: 'reports-page',
                    query: {
                      url: this.CurrentReport,
                      id: this.seasons,
                      code: this.code,
                      title: this.CurrentTitle,
                    },
                  }">Leaderboard</b-nav-item>  
              </template>         
            </template>
            <b-navbar-brand to="/">
              <img
                class="logoImg"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/05/LET20-LOGO-White.png'"
              />
            </b-navbar-brand>
            <!-- <template v-if="days_away == '0' || days_away == '-4' || days_away == '-3'|| days_away == '-2' || days_away == '-1'"> -->
            <template v-if="live_scoring == 'Y'">
              <b-nav-item v-if="this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only') || this.CurrentTitle.includes('Scoreboard')" class="menuBar mobHide LeaderboardBtn pulse break" :to="{
                  name: 'reports-page',
                  query: {
                    url: this.CurrentReport,
                    id: this.seasons,
                    code: this.code,
                    title: this.CurrentTitle,
                  },
                }">Leaderboard</b-nav-item>
            </template>
            <b-nav-item class="menuBar mobHide break RTCDS" :to="{
                name: 'order-of-merit',
                query: { id: curseasons, oom: 'PT'},
              }">Order Of Merit</b-nav-item>
            <b-nav-item class="menuBar mobHide break" href="https://www.solheimcup2026.golf/" target="_blank">Solheim Cup</b-nav-item>
            <!-- <b-nav-item class="menuBar mobHide break" :to="'/teameurope'" target="_blank">Solheim Cup</b-nav-item> -->
            <!-- <b-nav-item class="menuBar mobHide break" :to="'/teameurope'" target="_blank"> Solheim Cup Team Europe</b-nav-item> -->
            <b-nav-item
              class="menuBar mobHide break"
              href="https://www.lpga.com/"
              target="_blank"
              >LPGA</b-nav-item
            >
            <b-nav-item
              :class="{leadRight : this.CurrentTitle == 'Live Scoring Monitor' || this.CurrentTitle.includes('Scores Only')}"
              class="break menuBar mobHide rightLink LETAS"
              href="https://letaccess.com/"
              target="_blank"
              >LET Access Series</b-nav-item
            >
            <template class="socialIcons-Group">
              <b-nav-item class="break menuBar mobHide socialIcon" href="https://www.tiktok.com/@letgolf?lang=en" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'facebook']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">
                <img class='newTwitter' src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png">
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.youtube.com/user/'+config.VUE_APP_YOUTUBE_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
              </b-nav-item>
              <b-nav-item class="break menuBar mobHide socialIcon" :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME" target="_blank">
                <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
              </b-nav-item>
            </template>
            <b-embed
              class="rolex"
              type="iframe"
              aspect="21by9"
              :src="config.VUE_APP_ROLEX"
              allowfullscreen
              style="height:90px;border:0;margin:0;padding:0;overflow:hidden;scroll:none" scrolling="NO" frameborder="NO"
            ></b-embed>
          </b-navbar>
        </b-container>
      </div>
    </div>
    <transition name="slide">
      <div class="slidein" :class="open ? 'open' : ''">
        <b-row>
          <b-col>
            <b-nav-item @click="toggle" to='/' class="mainItem">Home</b-nav-item>
            <b-nav-item @click="toggle" class="mainItem" :to="'/tournaments'">
              Tournaments
            </b-nav-item>
            <b-nav-item @click="toggle" class="mainItem" to="/news">News</b-nav-item>
            <b-nav-item @click="toggle"
              class="mainItem"
              :to="{
                name: 'players',
              }"
              >Players</b-nav-item
            >

            <!-- <b-nav-item v-b-toggle="'collapse-3'" class="rankingsMenu mainItem">
              Players <font-awesome-icon class="arrowDown" :icon="['fas', 'arrow-down']" />
            </b-nav-item>
            <b-collapse id="collapse-3">
              <ul>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'players',
                  }">Full List</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'active-players',
                  }">Active Members</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'founders-players',
                  }">Founders</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'associate-players',
                  }">Associates</b-nav-item>
                </li>
              </ul>
            </b-collapse> -->
            <b-nav-item @click="toggle"
              class="mainItem"
              :to="{
                name: 'videos',
                query: { id: '' },
              }"
              >Videos</b-nav-item
            >
            <b-nav-item @click="toggle"
              class="mainItem" to='/the-let-golf-podcast'
              >The LET Golf Podcast</b-nav-item
            >
            <b-nav-item @click="toggle"
              class="mainItem statsUpdate"
              style="padding-bottom:10px"
              :to="{
                name: 'stats',
                query: { id: curseasons, full: true },
              }"
              >Stats</b-nav-item
            >
            <b-nav-item @click="toggle"
              class="mainItem statsUpdate"
              :href="'https://let-stats.com/'"
              target="_blank"
              >Strokes Gained Stats</b-nav-item
            >
            <b-nav-item v-b-toggle="'collapse-2'" class="rankingsMenu mainItem">
              Rankings <font-awesome-icon class="arrowDown" :icon="['fas', 'arrow-down']" />
            </b-nav-item>
            <b-collapse id="collapse-2">
              <ul>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'order-of-merit',
                    query: { id: curseasons, oom: 'PT'},
                  }">Order Of Merit</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'order-of-merits',
                    query: { id: curseasons, oom: 'OM'},
                  }">Money List</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'rookie-of-the-year-let',
                    query: { id: curseasons, oom: 'RK'},
                  }">Rookie Of The Year</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'let-cup-solheim',
                    query: { id: seasons, oom: 'SC'},
                  }">Solheim Cup Points</b-nav-item>
                </li>
                <li class="liRanksLinks">
                  <b-nav-item class="rankLinks" @click="toggle" :to="{
                    name: 'rolex-world-rankings',
                    query: { id: seasons, oom: 'WR'},
                  }">Rolex Ranking</b-nav-item>
                </li>
              </ul>
            </b-collapse>
            <b-nav-item @click="toggle" class="mainItem"  href="https://www.solheimcup2026.golf/" target="_blank">Solheim Cup</b-nav-item>
            <!-- <b-nav-item @click="toggle" class="mainItem"  :to="'/teameurope'" target="_blank">Solheim Cup</b-nav-item> -->
            <template v-if="allow_qschool == 'B' || allow_qschool == 'W'">  
              <b-nav-item @click="toggle" class="mainItem" :to="{
                  name: 'tourschool',
                  query: { id: curseasons,},
                }">Q-School</b-nav-item>
            </template>
            <b-nav-item @click="toggle" class="mainItem" :to="'/blog/' + green.slug"
                >Celebrating The Green</b-nav-item>
            <b-nav-item @click="toggle" class="mainItem" to='/partners-suppliers'>Partners & Suppliers</b-nav-item> 
            <template v-if="isMobile(true)">
              <b-nav-item class="mainItem" href='https://letaccess.com/'>LET Access Series</b-nav-item> 
            </template>
            <!-- <b-nav-item @click="toggle" class="mainItem" to='/develop'>DEVELOPMENT</b-nav-item> -->
          </b-col>
          <b-col class="FeaturedNews-mob">
            <h3 class="menuFeat">
              Featured News
            </h3>
            <MenuFeat />
          </b-col>
        </b-row>
        <b-row class="BannerHideMob">
          <b-col>
            <b-nav-item v-b-modal.app>
              <b-img
                class="menuBanner"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2021/03/follow-1.jpeg'"
              ></b-img>
            </b-nav-item>
            <b-modal centered='true' hide-footer='true' hide-header='true' id="app">
              <b-row>
                <b-col>
                  <a
                    :href="config.VUE_APP_GOOGLE_PLAY" target="_blank">
                      <b-img class='appLogo' src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-4.png'></b-img>
                  </a>
                </b-col>
                <b-col>
                  <a
                    :href="config.VUE_APP_APPLE_STORE" target="_blank">
                      <b-img class='appLogo'  src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/tartanprotour/2021/03/Mask-Group-5.png"></b-img>
                  </a>
                </b-col>
              </b-row>
            </b-modal>
          </b-col>
        </b-row>
        <!-- <b-row class="BannerHideMob">
          <b-col>
            <b-nav-item @click="toggle" to='newsletter/507'>
              <b-img
                class="menuBanner"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/05/LET20-WEBADST-Generic-Newsletter.jpg'"
              ></b-img>
            </b-nav-item>
          </b-col>
        </b-row> -->
        <button class="close-btn" @click="toggle">×</button>
      </div>
    </transition>
  </div>
</template>

<script>
import MenuFeat from "@/components/menuFeat.vue";
import axios from "axios";
export default {
  name: "Menu",
  props:['config'],
  components: {
    MenuFeat,
  },
  data() {
    return {
      menueLink: "menuLink",
      seasons: [],
      code: [],
      reports: [],
      open: false,
      green:[],
      breakC:[],
      CurrentTitle: '',
      CurrentReport: '',
      roundPlayed: [],
      days_away: '',
      allow_qschool: '',
      prevseason: [],
      curseasons: [],
      live_scoring: '',
      matchplay: ''
    };
  },
  computed: {
    filterReports: function() {
      if (this.matchplay == 'Y' && this.roundPlayed == '0' || this.roundPlayed == '1') {
        if (Array.isArray(this.reports)) {
          return this.reports.filter(
            (reports_entry) =>
              reports_entry.report_title.includes("Tournament Entries") ||
              reports_entry.report_title.includes("Score") ||
              // reports_entry.report_title.includes("Live") ||
              reports_entry.report_title.includes("Final")
          );
        } else return this.reports;     
      } else if((this.matchplay == 'Y' && this.roundPlayed > 1)){
        if (Array.isArray(this.reports)) {
          return this.reports.filter(
            (reports_entry) =>
              reports_entry.report_title.includes("Tournament Entries") ||
              reports_entry.report_title.includes("Scoreboard") ||
              // reports_entry.report_title.includes("Live") ||
              reports_entry.report_title.includes("Final")
          );
        } else return this.reports; 
      } else {
        if (Array.isArray(this.reports)) {
          return this.reports.filter(
            (reports_entry) =>
              reports_entry.report_title.includes("Tournament Entries") ||
              reports_entry.report_title.includes("Score") ||
              // reports_entry.report_title.includes("Live") ||
              reports_entry.report_title.includes("Final")
          );
        } else return this.reports; 
      }
    },
  },
  methods: {
    getOOM(oomSeason) {
      console.log('getOOM', oomSeason)
        axios.get(
          process.env.VUE_APP_TIC_BASE +
            oomSeason +
            "/" +
            oomSeason +
            "-ooms-oom-" +
            process.env.VUE_APP_OOM +
            ".json?randomadd=" +
            new Date().getTime()
        )
        .then(response => {
          this.oom = response.data;
          if (this.oom.oom_available != 'Y') {
            if (!isNaN(oomSeason)) {
              this.getOOM(oomSeason - 1) 
              this.curseasons = oomSeason - 1 
            }
          } else {
            this.curseasons = oomSeason
          }
        });
    },
    isMobile() {
      if (screen.width <= 428) {
        return true;
      } else {
        return false;
      }
    },
    toggle() {
      this.open = !this.open;
    },
    toggleClass: function() {
      this.isActive = !this.isActive;
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports)) {
        return this.filterReports[this.filterReports.length - 1].report_url;
      }
        else return this.filterReports.report_url
        .replace('https://info.ladieseuropeantour.com/tic/', '')
        .replace('.cgi?', '')
        .replace('tourn=', '')
        .replace('season=', '')
        .replace(this.code, '')
        .replace(this.seasons, '')
        .replace('~~alphaorder~', '')
    },
    currentTitle: function() {
      if (Array.isArray(this.filterReports)) {
        return this.filterReports[this.filterReports.length - 1].report_title;
      }
      else return this.filterReports.report_title;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE+"tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.allow_qschool = response.data.tm_params.allow_qschool;
        this.matchplay = response.data.match_play;
        console.log("this.allow_qschool")
        console.log(this.allow_qschool)
        this.live_scoring = response.data.live_scoring;
        this.roundPlayed = response.data.rounds_played;
        this.seasons = response.data.tm_params.season_code;
        this.prevseason = parseInt(this.seasons) - 1;
        this.code = response.data.code;
        this.reports = response.data.reports.reports_entry;
        this.days_away = response.data.days_away;
        this.$emit("season", { season: this.seasons });
        this.$emit("course", { course: this.code });
        this.$emit("report", { report: this.currentReport() });
        this.$emit("title", { title: this.currentTitle() });
        this.CurrentTitle = this.currentTitle();
        this.CurrentReport = this.currentReport();
        this.getOOM(this.seasons);
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/163458"
        );
      })
      .then((response) => {
        this.breakC = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/165434"
        );
      })
      .then((response) => {
        this.green = response.data; 
      });
  },
};
</script>

<style scoped>
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #01477b;
  border-color: #01477b;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5)!important;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #01477b;
  border-color: #01477b;
}
::v-deep .RTCDS {
  margin-left: 50px;
}
li.nav-item.menuBar.mobHide.rightLink.LETAS.leadRight {
  margin-right: 60px;
}
.menuContainer {
  padding-left: 0px;
}
::v-deep .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  /* margin-right: 5.2rem; */
  margin-right: 0rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
img.newTwitter {
  width: 11px;
  margin-top: -4px;
}
.pulse {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.LeaderboardBtn {
  background-color: #ff5c39;
  text-transform: uppercase;
  padding: 0px 10px;
  font-weight: 700;
  font-size: .675rem;
  color: #fff;
  border-radius: 3px;
  margin-left: 23px;
  margin-right: -29px;
}
.LeaderboardBtnMob {
  background-color: #ff5c39;
  text-transform: uppercase;
  padding: 0px 10px;
  font-weight: 700;
  font-size: .675rem;
  color: #fff;
  border-radius: 3px;
}
li.nav-item.rankLinks:hover {
  color: #fff;
  background: #42a7c6;
}
li.nav-item.rankLinks >a:hover {
  color: #fff;
}
li.liRanksLinks {
  list-style-type: disc;
  color: #95c93d;
}
div#collapse-2 {
  padding-bottom: 0px;
}
li.rankLinks > a {
  text-decoration: none;
  font-size: 13pt;
  color: #015699;
  display: block;
  transition: .3s;
  text-align: left;
  padding: 3px;
  width: 100%;
  padding-left: 5px;
}
svg.arrowDown.svg-inline--fa.fa-arrow-down.fa-w-14 {
  font-size: 13px;
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}
li.nav-item.rankingsMenu.mainItem {
  margin-top: 9.5px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.statsUpdate > a {
  margin-bottom: 0px!important;
}
li.nav-item.menuBar.mobHide.rightLink {
  margin-right: 80px;
}
@media (min-width: 992px) {
  ::v-deep .navbar-expand-lg {
    /* flex-flow: row nowrap; */
    justify-content: flex-start;
  }
}
li.nav-item.menuBar.mobHide.socialIcon > a {
  padding: 0;
}
li.nav-item.menuBar.mobHide.socialIcon {
  margin-right: 10px;
}
::v-deep .slidein {
  width: 600px;
  padding: 2em 3em;
  position: absolute;
  z-index: 100;
  left: 0;
  top: -102%;
  background: #fff;
  height: 100%!important;
  box-shadow: 5px 5px 10px #035599;
  transition: all 0.5s ease-in-out;
  z-index: 9909;
  display: none;
  /* overflow: hidden; */
}
::v-deep li {
  list-style-type: none;
}
.menu {
  background-color: #01477b;
  z-index: 200;
}
.mainItem > .nav-link {
  text-decoration: none;
  font-size: 13pt;
  color: #015699;
  display: block;
  transition: 0.3s;
  text-align: left;
  padding: 3px;
  width: 100%;
  padding-left: 5px;
  margin-bottom: 9.5px;
}
.mainItem > .nav-link:hover {
  color: #fff;
  background: #42a7c6;
}
.dropdown:hover {
  background: #42a7c6;
}
::v-deep .dropdown > a:hover {
  color: #fff !important;
}
.dropdown {
  text-decoration: none;
  font-size: 13pt;
  color: #015699 !important;
  display: block;
  transition: 0.3s;
  text-align: left;

  width: 100%;
}

::v-deep .dropdown > a {
  padding: 0.5rem 0.3rem;
  color: #015798;
}
.navbar {
  padding: 0;
}
.menuFeat {
  padding-left: 4px;
  text-align: left;
  color: #01477b;
  margin-top: 5px;
  font-weight: 400;
  margin-bottom: 15px;
}
.menuBanner {
  width: 100%;
}
.logoImg {
  width: 60px;
}
.burger {
  width: 100%;
}
.burgerOuter {
  background-color: transparent;
  border: none;
  width: 55px;
}
.burgerOuter:hover {
  background-color: transparent;
  border: none;
}

.slidein {
  width: 600px;
  padding: 2em 3em;
  position: absolute;
  z-index: 100;
  left: 0;
  /* top: -102%; */
  top: -120%;
  background: #fff;
  /* height: 850px!important; */
  box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  transition: all 0.5s ease-in-out;
  z-index: 999;
}

/* Set positioning back to 0 when toggled opened */
.open {
  /* top: 90px;
  height: 1000px!important;
  display: block; */
    top: 90px;
    height: initial !important;
    display: block;
}

/* add a close button in case toggle button is hidden on smaller screens */
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 50px;
  background: transparent;
  position: absolute;
  right: 0;
  top: -25px;
  padding: 0.5em;
  color: #01477b;
}
.slide-enter,
.slide-leave-active {
  top: -100%;
}
.menuBar > .nav-link {
  color: #fff;
  /* padding: 0 19px; */
  font-weight: 400;
  font-size: 0.775rem;
  letter-spacing: 0.5px;
}
.icon {
  font-size: 14px;
}
.embed-responsive {
  height: 90px;
  width: 291px;
  margin-right: -100px;
  margin-left: 20px;
}
.appLogo{
  width: 100%;
}
div#app {
    /* overflow: hidden; */
    position: absolute;
}
@media only screen and (max-width: 1200px) {
  .break {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  /* .rolex{
    display: none;
  } */
  /* .embed-responsive {
    display: none;
  } */
  .embed-responsive {
    height: 90px;
    margin-right: -100px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    background: #00603a;
    z-index: 99;
    margin-left: 0!important;
    margin-right: 0!important;
    padding-left: 0px;
    left: 0;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide{
    display: none;
  }
  .logoImg {
    width: 40px;
  }
  .open {
    top: 58px;
  }
}
/* @media only screen and (max-width: 600px) {
  .slidein {
    width: 100%;
    height: 100%;
    background-color: #015699;
  }
} */
@media only screen and (max-width: 600px) {
  ::v-deep div#collapse-2 > ul {
    padding-left: 0;
  }
  ::v-deep svg.arrowDown.svg-inline--fa.fa-arrow-down.fa-w-14{
    font-size: 13px;
    float: right;
    margin-top: 10px!important;
    margin-right: 5px;
  }
  ::v-deep li.liRanksLinks {
    list-style-type: none!important;
  }
  ::v-deep div#collapse-2 {
    padding-bottom: 0px;
    background: #03477b;
    /* margin-top: -15px; */
    margin-top: -9px;
    padding-bottom: 1px;
    padding-top: 10px;
  }
  ::v-deep li.rankLinks > a {
    text-decoration: none;
    font-size: 13pt;
    color: #fff;
    display: block;
    transition: .3s;
    text-align: left;
    padding: 3px;
    width: 100%;
    padding-left: 5px;
    /* padding-left: 18px; */
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .slidein {
    width: 100%;
    height: 100%;
    background-color: #015699;
    padding: 0em;
    padding-bottom: 3em!important;
    padding-top: 3em!important;
  }
  .FeaturedNews-mob {
    display: none;
  }
  .BannerHideMob {
    display: none;
  }
  ::v-deep .mainItem > .nav-link {
    text-decoration: none;
    font-size: 15pt;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    text-align: left;
    /* padding: 3px; */
    padding: 0.2em 2em!important;
    width: 100%;
    padding-left: 5px;
    /* margin-bottom: 15.5px; */
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }
    ::v-deep .mainItem > .nav-link:active {
    text-decoration: none;
    font-size: 15pt;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    text-align: left;
    padding: 3px;
    width: 100%;
    padding-left: 5px;
    margin-bottom: 15.5px;
    background-color: #42a7c6;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }
  ::v-deep .dropdown > a {
    padding: 0.5rem 0.3rem;
    color: #ffffff;
    font-size: 15pt;
    padding-bottom: 25px;
  }
  ::v-deep .mainItem > .nav-link:hover {
    color: #fff;
    background: #42a7c6;
    width: 100%;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    /* width: 300px; */
  }
  ::v-deep .close-btn {
    border: none;
    font-weight: 300;
    font-size: 3em;
    background: transparent;
    position: absolute;
    right: 0;
    /* top: -11px; */
    top: -25px;
    padding: 0.5em;
    color: #ffffff;
  }
}

</style>
