<template>
  <div class="sback">
    <b-container>
      <b-row class="sponsors">
        <b-col>
          <h3 class="title">
            Partners & Suppliers
          </h3>
          <template v-if="isMobile(true)">
            <b-carousel id="carousel-2" :interval="4000" img-width="1024">
              <b-carousel-slide
                img-blank
              >
                <b-row>
                  <b-col v-for="(spon, index) in partnerFilter" :key="index">
                    <b-link :href="spon.acf.footer_sponsor_link" target="_blank">
                      <b-img
                        class="spLogo"
                        :src="spon.acf.footer_sponsor_image"
                      ></b-img>
                    </b-link>
                  </b-col> </b-row
              ></b-carousel-slide>
              <b-carousel-slide
                img-blank
              >
                <b-row>
                  <b-col v-for="(spon, index) in supplierFilter" :key="index">
                    <b-link :href="spon.acf.footer_sponsor_link" target="_blank">
                      <b-img
                        class="spLogo"
                        :src="spon.acf.footer_sponsor_image"
                      ></b-img>
                    </b-link>
                  </b-col> </b-row
              ></b-carousel-slide>
            </b-carousel>
          </template>
          <template v-else>
            <b-carousel id="carousel-1" :interval="4000" img-width="1024">
              <b-carousel-slide
                img-blank
              >
                <b-row>
                  <b-col v-for="(spon, index) in partnerFilter.slice(0,5)" :key="index">
                    <b-link :href="spon.acf.footer_sponsor_link" target="_blank">
                      <b-img
                        class="spLogo"
                        :class="spon.acf.footer_class"
                        :src="spon.acf.footer_sponsor_image"
                      ></b-img>
                    </b-link>
                  </b-col> 
                </b-row>
                <b-row>
                  <b-col v-for="(spon, index) in partnerFilter.slice(5,13)" :key="index">
                    <b-link :href="spon.acf.footer_sponsor_link" target="_blank">
                      <b-img
                        class="spLogo"
                        :class="spon.acf.footer_class"
                        :src="spon.acf.footer_sponsor_image"
                      ></b-img>
                    </b-link>
                  </b-col> </b-row
              ></b-carousel-slide>
              <!-- <b-carousel-slide
                img-blank
              >
                <b-row>
                  <b-col v-for="(spon, index) in supplierFilter" :key="index">
                    <b-link :href="spon.acf.footer_sponsor_link" target="_blank">
                      <b-img
                        class="spLogo"
                        :src="spon.acf.footer_sponsor_image"
                      ></b-img>
                    </b-link>
                  </b-col> </b-row
              ></b-carousel-slide> -->
            </b-carousel>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sponsors",
  data() {
    return {
      tourSuppliers: [],
      tsRowTwo: [],
    };
  },
  computed: {
    supplierFilter: function() {
      return this.tsRowTwo.filter(
        (partner) => partner.acf.partner === true
      );
    },
    partnerFilter: function() {
      return this.tourSuppliers.filter(
        (partner) => !partner.acf.supplier === true
      );
    },
  },
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/footersponsors?per_page=12")
      .then((response) => {
        this.tourSuppliers = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+ "wp/v2/footersponsorsrowtwo"
        );
      })
      .then((response) => {
        this.tsRowTwo = response.data;
      });
  },
};
</script>

<style scoped>
.three {
  margin-top: 40px!important;
}
.four {
  margin-top: 40px!important;
}
.five {
  margin-top: 40px!important;
}
.fiveFor {
  margin-top: 53px!important;
}
.whoopMargin {
  margin-top: 50px!important;
}
.sback {
  background-color: #015699;
  padding: 10px 0;
}
.spLogo {
  /* width: 130px; */
  width: 104px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 0;
}
.title {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
::v-deep .carousel-inner {
  /* height: 135px; */
  height: 285px;
}

::v-deep .carousel-caption{
      top: 0;
      /* right: auto;
      left: auto; */
}
@media only screen and (max-width: 500px) {
  ::v-deep .carousel-caption{
      top: 0;
      right: auto;
      left: auto;
  }
}
</style>
